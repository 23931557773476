import React, { useState, useEffect } from "react";
import "../styles/stylesheet.css";
import Hero from "./Hero";
import Features from "./Features";
import marked from "marked";
import { getBiog, getCalendar, getBlogPosts } from "../contentful";
import TitleBar from "./TitleBar";
import NewsEventsShort from "./NewsEventsShort";
import { Row, Col } from "react-bootstrap";
import portrait from "../assets/imgs/new/front.jpg";

const Main = () => {
  const [biog, setBiog] = useState("");
  const [news, setNews] = useState([]);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    getBiog().then((data) => setBiog(data[0].fields.short));
    getCalendar().then((data) => setEvents(data));
    getBlogPosts().then((data) => setNews(data));
  }, []);

  const welcome = marked(biog);

  return (
    <div>
      <Hero />
      <div className="contents">
        <TitleBar title="Welcome" sharing="https://www.davidyacus.com" />
        <Row>
          <Col md={4}>
            <img
              src={portrait}
              style={{ width: "100%", marginBottom: "10px" }}
              alt=""
            />
          </Col>
          <Col md={8}>
            {" "}
            <div dangerouslySetInnerHTML={{ __html: welcome }} />
            <Features />
          </Col>
        </Row>

        <NewsEventsShort events={events} news={news} />
      </div>
    </div>
  );
};

export default Main;
