import React from 'react'
import marked from 'marked'
import { Row, Col } from 'react-bootstrap'
import { FaArrowCircleRight } from 'react-icons/fa'
import { Link } from 'react-router-dom'

const NewsEventsShort = props => {


    return (
        <div>
            <Row>
                <Col md={6}>
               <h4>News</h4>
               <p>The most recent posts from my news and commentary column, <Link to="/posts"><strong> 'La Ghirlanda'</strong></Link></p>
               {
                   props.news.slice(0,3).map(post => {
                    const body = marked(post.fields.content)
                    const excerpt = body.split(" ").splice(0, 40).join(" ") + '...';
                    const date = new Date(post.fields.date)
                    
                    return <div className="event">
                        <h6>{post.fields.title}</h6>
                   <p>{date.toDateString()}</p>
                        <section dangerouslySetInnerHTML={{__html: excerpt }} />
                    </div>
                   }
                    )
               }
               <Link to="/posts" className="btn btn-light">read all articles  <FaArrowCircleRight /></Link>
                </Col>
                <Col md={6}>
                <h4> Upcoming Events</h4>
                {
                    props.events.slice(0,3).map(event => {
                        const date = new Date(event.fields.date)

                       return <div className="event" >
                            <h6>{event.fields.title}</h6>

                            <p>{date.toDateString()} at {date.getHours()}:{String(date.getMinutes()).padStart(2, '0')}</p>

                    <p>{event.fields.venue}</p>
                        </div>
                    }
                        )
                }
                <Link to="/calendar" className="btn btn-light">view all events  <FaArrowCircleRight /></Link>

                </Col>
            </Row>
        </div>
    )
}

export default NewsEventsShort;
