import React from 'react'
import Instrument from './Instrument'
import { getInstrumentGallery, getInstrumentGalleryWelcome } from '../contentful'
import TitleBar from './TitleBar'
import marked from 'marked'

const imageStyle = {
    width: '65px'
    }



class InstrumentGallery extends React.Component {
  state = {
    posts: [],
    welcome: ''
  }

  
  getMarkdownText() {
    var rawMarkup = marked(this.state.welcome);
    return { __html: rawMarkup };
  }
  
  componentDidMount() {
    window.scrollTo(0, 0);

    getInstrumentGallery().then(data => this.setState({posts: data}))
    getInstrumentGalleryWelcome().then(data => this.setState({welcome: data[0].fields.content}))
    this.orderbyTitle();
  }
  
  orderbyTitle = () => {
    const sorted = this.state.posts.sort((a, b) => {
      if(a.fields.name < b.fields.name) return -1;
      if(a.fields.name > b.fields.name) return 1;
      return 0;
    })
    this.setState({...this.state, posts: sorted })
  }
   


  render() {

    return <div className='contents'>
<TitleBar title="Theatrum Instrumentorum - Instrument Gallery" sharing="https://www.davidyacus.com/instruments" />
<div dangerouslySetInnerHTML={this.getMarkdownText()} />
    {this.state.posts.map((item) => 
        <Instrument
        info={item}/>)}
  </div>

    }
}

export default InstrumentGallery