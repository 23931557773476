import React, {useState, useEffect} from 'react'
import { getInstrument } from '../contentful'
import InstrumentFull from './InstrumentFull'

const InstrumentSingle = (props) => {
  const [instrument, setInstrument] = useState([])


useEffect(() => {
    getInstrument(props.match.params.id).then(data => 
       setInstrument(data) )}, [])




return (
    <div>
    {instrument.map(instrument => <InstrumentFull selectedInstrument={instrument} />)}
        </div>
)
    }


export default InstrumentSingle;