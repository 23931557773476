import React from 'react'
import { getBlogPosts } from '../contentful'
import BlogItems from './BlogItems'
import TitleBar from './TitleBar'
import { Row, Col } from 'react-bootstrap'
import portrait from '../assets/imgs/new/blog.jpg'

class Posts extends React.Component {
  state = {
    posts: []
  }



 
  componentDidMount() {
    window.scrollTo(0, 0);
    getBlogPosts().then(data => this.setState({posts: data}))
  }
  
   
 
  render() {
    return (
      
        <div className='contents'>
                      <TitleBar title="'La Ghirlanda'" subtitle="My news, views, and commentary on a variety of subjects." />

          <Row>
            <Col md={3}>
            <img style={{width:'100%'}} src={portrait} alt=""/></Col>
            <Col md={9}>
<BlogItems posts={this.state.posts}/>

            </Col>
          </Row>

      
     
    </div>
    )
  }
}
export default Posts