import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Dropdown, NavItem, NavLink } from "react-bootstrap";
import { FaInstagram } from "react-icons/fa";

const header = {
  color: "black",
};
class Nav extends Component {
  constructor(props) {
    super(props);
    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true,
    };
  }
  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  render() {
    const collapsed = this.state.collapsed;
    const classOne = collapsed
      ? "collapse navbar-collapse"
      : "collapse navbar-collapse show";
    const classTwo = collapsed
      ? "navbar-toggler navbar-toggler-right collapsed"
      : "navbar-toggler navbar-toggler-right";
    return (
      <div style={{ textAlign: "center", marginBottom: "30px" }}>
        <Link to="/">
          {" "}
          <h2 style={header} className="display-4 text-center">
            David J Yacus
          </h2>
        </Link>
        <div id="nav">
         <div>                      <Link to="/biography">Biography</Link>
</div>
              <div>
                <Dropdown as={NavItem}>
                  <Dropdown.Toggle as={NavLink}>Musician</Dropdown.Toggle>
                  <Dropdown.Menu>
                   
                    <Dropdown.Item>
                      {" "}
                      <Link to="/instruments">Theatrum Instrumentorum</Link>
                    </Dropdown.Item>
                   
                    <Dropdown.Item>
                      {" "}
                      <Link to="/calendar">Calendar</Link>
                    </Dropdown.Item>

                    <Dropdown.Item>
                      {" "}
                      <Link to="/discography">Discography</Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      {" "}
                      <Link to="reviews">Reviews</Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      {" "}
                      <Link to="/links">Links</Link>
                    </Dropdown.Item>
                   
                  </Dropdown.Menu>
                </Dropdown>  
            </div>
         

          <div>
            <Dropdown as={NavItem}>
              <Dropdown.Toggle as={NavLink}>Educator</Dropdown.Toggle>
              <Dropdown.Menu>
              <Dropdown.Item>
                  {" "}
                  <Link to="/teaching">Teaching</Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  {" "}
                  <Link to="/tutorials">Tutorial Videos</Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  {" "}
                  <Link to="/downloads">Free Downloads</Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  {" "}
                  <Link to="/gallery">Gallery</Link>
                </Dropdown.Item>
                <Dropdown.Item>
                      <Link to="/posts">'La Ghirlanda' - news & commentary</Link>
                    </Dropdown.Item>
              
                <Dropdown.Item>
                  {" "}
                  <a href="https://www.instagram.com/davidyacus_trombone/">
                    {" "}
                    <FaInstagram size={20} />
                  </a>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <div>
          <Link className="nav-item" to="/contact">
            Contact
          </Link>
          </div>
        </div>
      </div>
    );
  }
}
export default Nav;
