import React, {Component} from 'react';
import Tutorial from './Tutorial'
import { getTutorials } from '../contentful'
import TitleBar from './TitleBar'

class Tutorials extends Component{
    state = {
        videos: []
    }

      componentDidMount() {
        window.scrollTo(0, 0);

        getTutorials().then(data => this.setState({videos: data}))
      }
      
           

    render(){
        return (
        <div className="contents">
          <TitleBar title="Tutorials" sharing="https://www.davidyacus.com/tutorials" />
        { this.state.videos.map(vid => <Tutorial tutorial={vid} />)}


        </div>
        )
    }
}

export default Tutorials