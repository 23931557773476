import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import marked from "marked";
import {
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  FacebookIcon,
  FacebookShareButton,
} from "react-share";
import { Link } from 'react-router-dom'
import ImageGrid from './ImageGrid'
import Modal from './Modal'
import {SRLWrapper} from 'simple-react-lightbox'

const imageStyle = {
  width: "100%",
};

const videos = {
  marginBottom: '30px',
  marginTop: '30px'
}

const InstrumentFull = ({ selectedInstrument }) => {
  const [instrument, setInstrument] = useState(selectedInstrument)
  const [selectedImg, setSelectedImg] = useState(null)
  const [title, setTitle] = useState(null)

  const description = marked(instrument.fields.description);


  return (
    <div className="contents">
      <div>
        <Row>
          <Col md={6}>
            <h5>{instrument.fields.name}</h5>
            <section dangerouslySetInnerHTML={{ __html: description }} />
          </Col>
          <Col md={6}>
            {instrument.fields.mainImage ? (
              <div>
              <img
              alt=""
                style={imageStyle}
                src={instrument.fields.mainImage.fields.file.url}
              />
            {instrument.fields.mainImage.fields.description && <small>{instrument.fields.mainImage.fields.description}</small>}
            </div>
            ) : null}
          </Col>
        </Row>
      </div>
            
            <div style={videos}>
      {instrument.fields.video && !instrument.fields.video2 ? (
        <div>
         <video className="video" controls>
            <source
              src={instrument.fields.video.fields.file.url}
              type="video/mp4"
            />
            Your browser does not support the video tag
          </video>{" "}
          
        </div>
      ) : null}

{instrument.fields.video && instrument.fields.video2 ? (
        <div>
          <Row>
            <Col md={6}>
            <video className="video" controls>
            <source
              src={instrument.fields.video.fields.file.url}
              type="video/mp4"
            />
            Your browser does not support the video tag
          </video>{" "}
            </Col>
            <Col md={6}>
            <video className="video" controls>
            <source
              src={instrument.fields.video2.fields.file.url}
              type="video/mp4"
            />
            Your browser does not support the video tag
          </video>{" "}
            </Col>
          </Row>
        </div>
      ) : null}
</div>
<SRLWrapper>

      <Row>
        {instrument.fields.gallery
          && instrument.fields.gallery.map((image) => (
            <Col md={3}>
           <img className="instrument-gallery-image" alt={image.fields.description} src={image.fields.file.url}/><br/>
          </Col>

            ))
          }
      </Row>
      </SRLWrapper>

<hr/>

      <Link className="btn btn-light" to="/instruments">back to Theatrum Instrumentorum</Link>
<div>
      <small>Share this page on your social media</small><br></br>
              <TwitterShareButton
                url={window.location}
                children={<TwitterIcon size={24} />}
              />
              <LinkedinShareButton
                url={window.location}
                children={<LinkedinIcon size={24} />}
              />
              <FacebookShareButton
                url={window.location}
                children={<FacebookIcon size={24} />}
              />
              </div>
    </div>
  );
};

export default InstrumentFull;
