import React, { useState, useEffect } from "react";
import marked from "marked";
import TitleBar from "./TitleBar";
import { getSinglePost } from "../contentful";
import { Link } from 'react-router-dom'

const SinglePost = (props) => {
  const [post, setPost] = useState([]);

  useEffect(() => {
    getSinglePost(props.match.params.id).then((post) => setPost(post));
  }, []);

  return (
    <div className="contents">
      {post.map((post) => {
        const date = new Date(post.fields.date);
        const content = marked(post.fields.content);

        return (
          <div>
            <TitleBar title={post.fields.title} />
            <p> Published by {post.fields.author.fields.name} on {date.toDateString()}</p>

            <hr/>
            {post.fields.image && (
              <img src={post.fields.image.fields.file.url} />
            )}
            {post.fields.video && (
              <video className="video" controls>
                <source
                  src={post.fields.video.fields.file.url}
                  type="video/mp4"
                />
                Your browser does not support the video tag
              </video>
            )}
            <div className="blog-content" dangerouslySetInnerHTML={{ __html: content }}></div>

          </div>
        );
      })}
      <Link to="/posts" className="btn btn-light">back to all articles</Link>
    </div>
  );
};

export default SinglePost;
