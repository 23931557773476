const client = require('contentful').createClient({
    space: 'f88mg4v0y8p4',
    accessToken: 'pio6G9RM7_vvX73By244z5DdjEihDxGr9TKHYdLnlcw'
  })

  var gt = new Date().toISOString();

  
  const getBlogPosts = () => client.getEntries({content_type:'blog'}).then(response => response.items)
  const getDownloads = () => client.getEntries({content_type: 'download', order: 'fields.composer'}).then(response => response.items)
  const getTutorials = () => client.getEntries({content_type:'tutorials'}).then(response => response.items)
  const getInstrumentGalleryWelcome = () => client.getEntries({content_type:'instrumentGalleryWelcome'}).then(response => response.items)
  const getDownloadsWelcome = () => client.getEntries({content_type:'downloadsWeclome'}).then(response => response.items)
  const getInstrumentGallery = () => client.getEntries({order: 'fields.instrumentYear',
    content_type:'instrumentGallery'}).then(response => response.items)
  const getInstrument = (slug) => client.getEntries({'fields.name': slug, content_type:'instrumentGallery'}).then(response => response.items)
  const getBiog = () => client.getEntries({content_type:'biography'}).then(response => response.items)
  const getCalendar = () => client.getEntries({content_type:'calendar', order: 'fields.date', 'fields.date[gte]': gt}).then(response => response.items)
  const getCalendarArchive = () => client.getEntries({content_type: 'calendar', order:'-fields.date', 'fields.date[lt]': gt
}).then(response => response.items)
  
  
  const getGallery = () => client.getEntries({content_type:'galleryPhoto', order: '-fields.date'}).then(response => response.items)
  const getRecordings = () => client.getEntries({content_type:'recording'}).then(response => response.items)
  const getCompleteRecordings = () => client.getEntries({content_type:'completeDiscography'}).then(response => response.items)
  const getReviews = () => client.getEntries({content_type:'review'}).then(response => response.items)
  const getLinks = () => client.getEntries({content_type:'link'}).then(response => response.items)
  const getTeaching = () => client.getEntries({content_type:'teaching'}).then(response => response.items)
  
  const getSinglePost = slug =>
    client
      .getEntries({
        'fields.title': slug,
        content_type: 'blog'
      })
      .then(response => response.items)
  
  export { getCalendarArchive, getTeaching, getCompleteRecordings, getReviews, getRecordings, getLinks, getGallery, getBlogPosts, getSinglePost, getDownloads, getTutorials,
  getInstrumentGallery, getInstrument, getBiog, getCalendar, getInstrumentGalleryWelcome, getDownloadsWelcome }