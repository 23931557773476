import React, { useState, useEffect } from 'react'
import TitleBar from './TitleBar'
import ImageGrid from './ImageGrid'
import Modal from './Modal'
import { getGallery } from '../contentful'
import {SRLWrapper} from 'simple-react-lightbox'
import { Row, Col } from 'react-bootstrap'





const Gallery = () => {
    const [selectedImg, setSelectedImg] = useState(null)
    const [title, setTitle] = useState(null)
    const [images, setImages] = useState([])


    useEffect(() => {
        getGallery().then(data => setImages(data))
    }, [])

   return <div className="contents">
    <TitleBar title="Photo Gallery"  />
    <SRLWrapper>
<div>
    <Row>
{images.map(image => 
<Col md={3} >
    <img className="gallery-image" src={image.fields.image.fields.file.url} alt={image.fields.title} style={{width: '100%'}} />
<p>{image.fields.title}</p>
    </Col>

)}

    </Row>
    </div>
    </SRLWrapper>


    {/* <ImageGrid images={images} setSelectedImg={setSelectedImg} setTitle={setTitle}/> */}
    {/* {selectedImg && <Modal selectedImg={selectedImg} setSelectedImg={setSelectedImg} title={title} setTitle={setTitle} />} */}
    </div>
};

export default Gallery;