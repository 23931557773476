import React from "react";
// JSX
import HeroSlider, { Slide, Nav, OverlayContainer } from "hero-slider";


// Images
import david0 from '../assets/imgs/new/header4.jpg'
import david1 from '../assets/imgs/new/header3.jpg'
import david2 from '../assets/imgs/new/header2.jpg'
import david3 from '../assets/imgs/new/header1.jpg'

const slider = () => {
  return (
    <HeroSlider
      slidingAnimation="fade"
      orientation="horizontal"
      initialSlide={1}
      onBeforeChange={(previousSlide, nextSlide) =>
        console.log("onBeforeChange", previousSlide, nextSlide)
      }
      onChange={nextSlide => console.log("onChange", nextSlide)}
      onAfterChange={nextSlide => console.log("onAfterChange", nextSlide)}
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.33)"
      }}
      settings={{
        slidingDuration: 1000,
        slidingDelay: 200,
        shouldAutoplay: true,
        shouldDisplayButtons: true,
        autoplayDuration: 3000,
        height: "80vh"
      }}
    >
      <OverlayContainer>
       <div>
       </div>
      </OverlayContainer>

      
      <Slide
      className="slider-background"
        background={{
          backgroundImage: david2,
          // backgroundAttachment: "fixed",
          backgroundPosition: 'top',

        }}
      />

      <Slide
      className="slider-background"
        background={{
          backgroundImage: david0,
          // backgroundAttachment: "fixed",
          backgroundPosition: 'top',

        }}
      />

      <Slide
            className="slider-background"

        background={{
          backgroundImage: david1,
          // backgroundAttachment: "fixed"
          backgroundPosition: 'top',

        }}
      />

      <Slide
            className="slider-background"

        background={{
          backgroundImage: david3,
          // backgroundAttachment: "fixed",
          backgroundPosition: 'top'

        }}
      />

      <Nav />
    </HeroSlider>
  );
};

export default slider;
