import React, {Component} from 'react'
import marked from 'marked'
import { getBiog } from '../contentful'
import TitleBar from './TitleBar'
import { Row, Col } from 'react-bootstrap'
import portrait from '../assets/imgs/new/biog.jpg'

class BiogFull extends Component {
    state = {
        biog: ''
      }
    
      getMarkdownText() {
        var rawMarkup = marked(this.state.biog);
        return { __html: rawMarkup };
      }

   componentDidMount(){
     getBiog().then(data => this.setState({biog: data[0].fields.biography}))
   }
     

    render(){

    return (
    
    <div className="contents">
        <TitleBar title="Biography" />
        <Row>
          <Col md={6}>
          <img style={{width:'100%'}} src={portrait} alt=""/></Col>
          <Col md={6}><div dangerouslySetInnerHTML={this.getMarkdownText()} />
</Col>
        </Row>

         </div>
    )
}
}

export default BiogFull