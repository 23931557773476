import React, { useState, useEffect } from "react";
import TitleBar from "./TitleBar";
import marked from "marked";
import { getLinks } from "../contentful";
import { Row, Col } from "react-bootstrap";

const Links = (props) => {
  const [links, setLinks] = useState([]);

  useEffect(() => {
    getLinks().then((data) => setLinks(data));
  }, []);

  return (
    <div>
      <div className="contents">
        <TitleBar title="Links" />

        <h4>Ensembles</h4>
        {links.map((link) => {
          let info;
          {
            link.fields.info
              ? (info = marked(link.fields.info))
              : (info = null);
          }

          if (link.fields.category === "ensemble") {
            return (
              <div className="event">
                <Row>
                  <Col md={3}>
                    {link.fields.image && (
                      <img
                        style={{ width: "100%" }}
                        src={link.fields.image.fields.file.url}
                        alt=""
                      />
                    )}
                  </Col>
                  <Col md={9}>
                    <h5>{link.fields.name}</h5>
                    {link.fields.info && (
                      <section dangerouslySetInnerHTML={{ __html: info }} />
                    )}
                    <a
                      className="btn btn-light"
                      href={link.fields.website}
                      target="_blank"
                    >
                      visit {link.fields.name}
                    </a>
                  </Col>
                </Row>
              </div>
            );
          }
        })}
        <h4>Instrument Makers</h4>
        {links.map((link) => {
          let info;
          {
            link.fields.info
              ? (info = marked(link.fields.info))
              : (info = null);
          }
          if (link.fields.category === "maker") {
            return (
              <div className="event">
                <Row>
                  <Col md={3}>
                    {link.fields.image && (
                      <img
                        style={{ width: "100%" }}
                        src={link.fields.image.fields.file.url}
                        alt=""
                      />
                    )}
                  </Col>
                  <Col md={9}>
                    <h5>{link.fields.name}</h5>
                    {link.fields.info && (
                      <section dangerouslySetInnerHTML={{ __html: info }} />
                    )}
                    <a
                      className="btn btn-light"
                      href={link.fields.website}
                      target="_blank"
                    >
                      visit {link.fields.name}
                    </a>
                  </Col>
                </Row>
              </div>
            );
          }
        })}

        <h4>Other</h4>
        {links.map((link) => {
          let info;
          {
            link.fields.info
              ? (info = marked(link.fields.info))
              : (info = null);
          }
          if (!link.fields.category) {
            return (
              <div className="event">
                <Row>
                  <Col md={3}>
                    {link.fields.image && (
                      <img
                        style={{ width: "100%" }}
                        src={link.fields.image.fields.file.url}
                        alt=""
                      />
                    )}
                  </Col>
                  <Col md={9}>
                    <h5>{link.fields.name}</h5>
                    {link.fields.info && (
                      <section dangerouslySetInnerHTML={{ __html: info }} />
                    )}
                    <a
                      className="btn btn-light"
                      href={link.fields.website}
                      target="_blank"
                    >
                      visit {link.fields.name}
                    </a>
                  </Col>
                </Row>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default Links;
