import React from "react";
import marked from "marked";

const Tutorial = (props) => {
  let description = null;
  if (props.tutorial.fields.description) {
    description = marked(props.tutorial.fields.description);
  }

  let youtube;
  if (props.tutorial.fields.youtubeCode)
    youtube = marked(props.tutorial.fields.youtubeCode);

  return (
    <div className='video-card'>
      {props.tutorial.fields.title && (
        <h4 style={{ textAlign: "center" }}>{props.tutorial.fields.title}</h4>
      )}
      {description && (
        <section dangerouslySetInnerHTML={{ __html: description }} />
      )}

      {props.tutorial.fields.youtubeCode && (
        <div
          className='youtube-video'
          dangerouslySetInnerHTML={{ __html: youtube }}
        />
      )}

      {props.tutorial.fields.video ? (
        <div>
          <video className='video' controls>
            <source
              src={props.tutorial.fields.video.fields.file.url}
              type='video/mp4'
            />
            Your browser does not support the video tag
          </video>{" "}
        </div>
      ) : null}
    </div>
  );
};

export default Tutorial;
