import React from 'react'
import marked from 'marked'
import {Row, Col} from 'react-bootstrap'
import { Link } from 'react-router-dom'



const Instrument = (props) => {

const description = marked(props.info.fields.description)
const excerpt = description.split(" ").splice(0, 35).join(" ") + "...";


    return(
        
        <div className="event" >
            <Row>
                <Col><h5>{props.info.fields.name}</h5>
                <section dangerouslySetInnerHTML={{ __html: excerpt }} />
                <Link className="btn btn-light" to={'/instruments/' + props.info.fields.name} >Click here for full description, photos and more...</Link>

                </Col>
                <Col>
                {!props.info.fields.video ? 
                <img style={{height:'250px'}} src={props.info.fields.mainImage.fields.file.url}/> : null}
                
                {props.info.fields.video ? 
        <div >
            
        <video className="video" controls>
            <source  src={props.info.fields.video.fields.file.url} type="video/mp4"/>
                Your browser does not support the video tag
        </video> </div> : null }</Col>
            </Row>

        <hr/>
        
        </div>
    );
}

export default Instrument