import React from 'react'
import marked from 'marked'
import { FaArrowCircleRight} from 'react-icons/fa'

const Event = (props) => {

    const details = marked(props.date.fields.description)

    const date = new Date(props.date.fields.date)
    return <div className="event">
        <hr></hr>
        <h5>{props.date.fields.title}</h5>
        <p>{date.toDateString()}</p>
<p>{date.getHours()}:{String(date.getMinutes()).padStart(2, '0')}</p>
        <p>{props.date.fields.venue}</p>
        {details ? 
        <section dangerouslySetInnerHTML={{ __html: details}}/> : null}
       { props.date.fields.link &&  <a className="btn btn-light" href={props.date.fields.link} target="_blank" >more information <FaArrowCircleRight /></a> }
        <hr></hr>
    </div>
}

export default Event
