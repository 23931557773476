import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { FaDownload } from 'react-icons/fa'

const DownloadItem = (props) => (
  <div className="box content">
   <Row>
     <Col md={6}>
       {props.item.fields.flag &&
       <img style={{height:'40px', marginBottom:'5px'}} src={props.item.fields.flag.fields.file.url} />
       }
  <h5>{props.item.fields.title}</h5>
<p>Composer: {props.item.fields.composer}</p>
<p>Number of Parts: {props.item.fields.parts}</p>
<p><em>{props.item.fields.description}</em></p>
<a className="btn btn-light" 
download 
href={props.item.fields.file.fields.file.url} target="_blank" >Download <FaDownload /></a>
 <div >

</div>
</Col>
<Col md={6}>
<object
      className="pdf"
      type="application/pdf"
      data= {props.item.fields.file.fields.file.url}
      height="250"
    >
    </object>

 </Col>
 </Row>
  </div>
)

export default DownloadItem