import React from "react";
import DownloadItem from "./DownloadItems";
import { Col, Row } from "react-bootstrap";
import TitleBar from "./TitleBar";
import { getDownloads, getDownloadsWelcome } from "../contentful";
import marked from "marked";
import portrait from "../assets/imgs/new/downloads.jpg";
import { FaFontAwesomeLogoFull } from "react-icons/fa";

class Downloads extends React.Component {
  constructor(props) {
    super(props);

    getDownloads().then((data) => {
      this.setState({
        posts: data,
        displayedPosts: data,
        composerList: data.map((composer) => composer.fields.composer),
      });
    });
    getDownloadsWelcome().then((data) =>
      this.setState({ welcome: data[0].fields.content })
    );

    this.state = {
      posts: [],
      displayedPosts: [],
      composerList: [],
      welcome: "",
    };
  }

  getMarkdownText() {
    var rawMarkup = marked(this.state.welcome);
    return { __html: rawMarkup };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.orderbyTitle();
  }

  orderbyComposer = () => {
    const sorted = this.state.displayedPosts.sort((a, b) => {
      if (a.fields.composer < b.fields.composer) return -1;
      if (a.fields.composer > b.fields.composer) return 1;
      return 0;
    });
    this.setState({ ...this.state, displayedPosts: sorted });
  };

  orderbyParts = () => {
    const sorted = this.state.displayedPosts.sort((a, b) => {
      return a.fields.parts - b.fields.parts;
    });
    this.setState({ ...this.state, displayedPosts: sorted });
  };

  orderbyTitle = () => {
    const sorted = this.state.displayedPosts.sort((a, b) => {
      if (a.fields.title < b.fields.title) return -1;
      if (a.fields.title > b.fields.title) return 1;
      return 0;
    });
    this.setState({ ...this.state, displayedPosts: sorted });
  };

  onSearch = (event) => {
    if(event.target.value != 'all'){
    const result = this.state.posts.filter((post) => {
      return post.fields.composer == event.target.value;
    });
    this.setState({ displayedPosts: result });
    // console.log(result);
  } else {
    this.setState({displayedPosts: this.state.posts})
  }
  };

 

  render() {
    const dropdown = this.state.composerList;
    const unique = [...new Set(dropdown)];

    return (
      <div className="contents">
        <TitleBar
          title="Free Downloads"
          sharing="https://www.davidyacus.com/downloads"
        />
       

        <Row>
          <Col md={4}>
            <img style={{ width: "100%" }} src={portrait} alt="" />
          </Col>
          <Col md={8}>
            <div dangerouslySetInnerHTML={this.getMarkdownText()} />
            <div style={{ marginBottom: "20px" }}>
              <form
                action="https://www.paypal.com/cgi-bin/webscr"
                method="post"
                target="_blank"
              >
                <input type="hidden" name="cmd" value="_s-xclick" />
                <input
                  type="hidden"
                  name="hosted_button_id"
                  value="XXWUG7XSHTFWU"
                />
                <input
                  type="image"
                  src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_SM.gif"
                  border="0"
                  name="submit"
                  title="PayPal - The safer, easier way to pay online!"
                  alt="Donate with PayPal button"
                />
                <img
                  alt=""
                  border="0"
                  src="https://www.paypal.com/en_DE/i/scr/pixel.gif"
                  width="1"
                  height="1"
                />
              </form>
            </div>
          </Col>
        </Row>

        <hr />
        <div className="sorting-buttons">
          <select className="composers-dropdown" onChange={this.onSearch}>
            <option value="all">Filter by Composer (All)</option>
            {unique.map((composer) => (
              <option value={composer} key={composer.index}>
                {composer}
              </option>
            ))}
          </select>
          {/* <button className="btn btn-light" onClick={this.orderbyComposer}>
            Sort by Composer
          </button> */}
          <button className="btn btn-light" onClick={this.orderbyTitle}>
            Sort by Title
          </button>
          <button className="btn btn-light" onClick={this.orderbyParts}>
            Sort by Parts
          </button>
      
        </div>
        <Row>
          {this.state.displayedPosts.map((item) => (
            <DownloadItem item={item} />
          ))}
        </Row>
        <br />
      </div>
    );
  }
}
export default Downloads;
