import React from "react";
import marked from "marked";
import { FaInstagram } from "react-icons/fa";
import {
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  FacebookIcon,
  FacebookShareButton,
} from "react-share";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const imageStyle = {
  width: "65px",
};

const BlogItem = ({ article }) => {
  console.log(article);
  const { title, image, video, content, author, date } = article.fields;
  const postBody = marked(content);
  const excerpt = postBody.split(" ").splice(0, 60).join(" ") + "...";
  const formattedDate = new Date(date);
  return (
    <div className='blog-item'>
      <h3>{title}</h3>
      <p>
        by {author && <span>{author.fields.name}</span>},{" "}
        {formattedDate.toDateString()}
      </p>
      <Row>
        {image && (
          <Col md={6}>
            {" "}
            <img style={imageStyle} src={image.fields.file.url} />
          </Col>
        )}
        {video && (
          <Col md={6}>
            <video className='video' controls>
              <source src={video.fields.file.url} type='video/mp4' />
              Your browser does not support the video tag
            </video>
          </Col>
        )}
      </Row>

      <section dangerouslySetInnerHTML={{ __html: excerpt }} />

      <Link to={"/posts/" + title} className='btn btn-light'>
        read more
      </Link>

      <TwitterShareButton
        url={window.location}
        children={<TwitterIcon size={24} />}
      />
      <LinkedinShareButton
        url={window.location}
        children={<LinkedinIcon size={24} />}
      />
      <FacebookShareButton
        url={window.location}
        children={<FacebookIcon size={24} />}
      />
    </div>
  );
};

export default BlogItem;
