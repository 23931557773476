import React from 'react'
import { FaInstagram } from 'react-icons/fa';
import { Row, Col } from 'react-bootstrap'
import portrait from '../assets/imgs/new/sitting.jpg'
const Contact = () => {
    return <> 
    <div className="contents back">
    <div className='contact'>
        <Row>
            <Col md={6}>
                <div className="contact-text">
            <h4> Contact Me</h4>
    <p>I'd love to hear from you!  Send me a mail to: <a href='mailto:info@davidyacus.com'>info@davidyacus.com</a></p>
<p>You can also follow me on Instagram</p>
<a href="https://www.instagram.com/davidyacus_trombone/"> <FaInstagram size={40} /></a>
</div>
            </Col>
            <Col md={6}>    
<img style={{width:'100%'}} src={portrait} alt=""/>
 
 </Col>
</Row>

   </div>
    </div>
    </>
}

export default Contact