import React from 'react';
import { FaCode, FaMusic, FaCameraRetro } from 'react-icons/fa';
import {Link} from 'react-router-dom';
import {Row, Col} from 'react-bootstrap'
import test from '../assets/imgs/herotest.jpg'
import score from '../assets/imgs/score.png'
import video from '../assets/imgs/video.png'

const style = {
    textDecoration: 'none'
}

const Features = () => {
    return <div className="choices">
        <Row>
            <Col md={4}>
        <Link style={style} to='/tutorials'>
        <div className="choice main-box">
        <h5>Video Tutorials</h5>
        <img style={{maxWidth:'100%'}} src={video} alt=""/>
        <div className="overlay"> Warm up, learn new techniques and improve your existing ones with my online video tutorials...</div>
        </div>
        </Link>
        </Col>
        <Col md={4}>
        <Link style={style} to='/instruments'>
        <div className="choice main-box">
        <h5>Theatrum Instrumentorum</h5>
        <img style={{maxWidth:'100%'}} src={test} alt=""/>
        <div className="overlay"> Video, photos and more information about my extensive instrument collection...</div>
        </div>
        </Link>
        </Col>
        <Col md={4}>
        <Link style={style} to='/downloads'>
        <div className="choice main-box">
        <h5>Downloads</h5>
        <img style={{maxWidth:'100%'}} src={score} alt=""/>
        <div className="overlay">My own editions and facsimile reproduction, available for download.</div>
        </div>
        </Link>
        </Col>
        </Row>
    </div>
}

export default Features