import React, { useState, useEffect } from "react";
import TitleBar from "./TitleBar";
import marked from "marked";
import { getReviews } from "../contentful";

const Reviews = props => {

    const [reviews, setReviews] = useState([])


useEffect(() => {
getReviews().then(data => setReviews(data))
}, []

)
    return (
        <div className="contents">
            <TitleBar title="Reviews" />
{ reviews.map(review => {
const content = marked(review.fields.content)
    return (
        <div className="event">
            <h5>{review.fields.title}</h5>
    <p>{review.fields.subtitle}</p>
    <section dangerouslySetInnerHTML={{__html: content}} />


        </div>
    )
})}





        </div>
    )
    
}

export default Reviews;