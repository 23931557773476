import React, { useState, useEffect } from "react";
import TitleBar from "./TitleBar";
import marked from "marked";
import { getRecordings, getCompleteRecordings } from "../contentful";
import { FaItunes, FaSpotify, } from "react-icons/fa";
import { Row, Col } from "react-bootstrap";

const Discography = (props) => {
  const [recordings, setRecordings] = useState([]);
  const [discs, setDiscs] = useState([]);

  useEffect(() => {
    getRecordings().then((data) => setRecordings(data));
    getCompleteRecordings().then((data) => setDiscs(data));
  }, []);

  return (
    <div>
      <div className="contents">
        <TitleBar title="Discography" />
        <p>See below for a complete list of recordings.</p>
        {recordings.map((cd) => {
          let info;
          {cd.fields.info ? info = marked(cd.fields.info) : info = null}
          let desc;
          {cd.fields.description ? desc = marked(cd.fields.description) : desc = null}
          return (
            <div className="event">
              <Row>
                <Col md={8}>
                  <h4>Featured Recording</h4>
                  <hr/>
                  <h5>{cd.fields.title}</h5>
                {cd.fields.description &&  <section dangerouslySetInnerHTML={{__html: desc}} />}
                {cd.fields.info &&  <section dangerouslySetInnerHTML={{ __html: info }} />}
              
<hr/>
                  { cd.fields.audio ? cd.fields.audio.map(track => (
                  <div>
                   <p>{track.fields.title}</p>
                  <audio className="video" controls>
                <source
                 src={track.fields.file.url}
                  type=""
                  />
                   Your browser does not support the video tag
                  </audio>
                  <hr/>
                  </div> )) : null}
               
                </Col>
                <Col md={4}>
                {cd.fields.image && <img
                    style={{ width:'100%' }}
                    src={cd.fields.image.fields.file.url}
                    alt=""
                 /> }
                   {cd.fields.video && <video className="video" controls>
                <source
                 src={cd.fields.video.fields.file.url}
                  type="video/mp4"
                  />
                   Your browser does not support the video tag
                  </video> }
                 

                 
                  <div style={{display: 'flex', justifyContent:'center'}}>
                  {cd.fields.itunesLink && <a id="itunes" href={cd.fields.itunesLink} target="_blank"> <FaItunes size={30}/></a>}
                  {cd.fields.spotifyLink && <a id="spotify" href={cd.fields.spotifyLink} target="_blank"> <FaSpotify size={30}/></a>}
                  </div>
                </Col>
              </Row>
            </div>
          );
        })}
        <h4>Complete Discography</h4>
        {discs.map(data => {
          const content = marked(data.fields.content)
          return <div dangerouslySetInnerHTML={{__html: content}} />


        })}


      </div>
    </div>
  );
};

export default Discography;
