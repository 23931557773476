import React from 'react';
import Main from './components/Main';
import Store from './components/Store';
import Contact from './components/Contact';
import Nav from './components/Nav1';
import Posts from './components/Posts'
import Footer from './components/Footer'
import Musician from './components/Musician'
import Discography from './components/Discography'
import Media from './components/Media'
import Downloads from './components/Downloads'
import Calendar from './components/Calendar'
import Gallery from './components/Gallery'
import SinglePost from './components/SinglePost'

import {BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import InstrumentGallery from './components/InstrumentGallery';
import Tutorials from './components/Tutorials'
import BiogFull from './components/BiographyFull'
import InstrumentSingle from './components/InstrumentSingle'
import Links from './components/Links';
import Reviews from './components/Reviews';
import Teaching from './components/Teaching'


function App() {
  return (
    <Router>
    <div>
    <Nav/>
    <Switch>
    <Route exact path="/" component={Main}/>
    <Route path="/store" component={Store}/>
    <Route path='/downloads' component={Downloads}/>
    <Route path="/contact" component={Contact}/>
    <Route path="/posts/:id" component={SinglePost}/>
    <Route path="/posts" component={Posts}/>
    <Route path="/musician" component={Musician}/>
    <Route path="/instruments/:id" component={InstrumentSingle}/>
    <Route path="/instruments" component={InstrumentGallery}/>
    <Route path="/calendar" component={Calendar}/>
    <Route path="/discography" component={Discography}/>
    <Route path="/media" component={Media}/>
    <Route path="/tutorials" component={Tutorials}/>
    <Route path="/biography" component={BiogFull}/>
    <Route path="/gallery" component={Gallery}/>
    <Route path="/links" component={Links}/>
    <Route path="/reviews" component={Reviews}/>
    <Route path="/teaching" component={Teaching}/>

    </Switch>
    <Footer/>

    </div>
    </Router>
  );
}

export default App;
