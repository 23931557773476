import React from "react";
import Event from "./Event";
import { getCalendar, getCalendarArchive } from "../contentful";
import TitleBar from "./TitleBar";
import { Row, Col } from "react-bootstrap";
import image from '../assets/imgs/new/sitting.jpg'

class Calendar extends React.Component {
  state = {
    posts: [],
    archive: [],
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    getCalendar().then((data) => this.setState({ posts: data }));
    getCalendarArchive().then((data) => this.setState({ archive: data }));
  }
  render() {
    return (
      <div className="contents">
        <TitleBar title="Calendar" />
        <Row>
          <Col md={8}>
            {this.state.posts.map((item) => (
              <Event date={item} />
            ))}
          </Col>
          <Col md={4}>
            {" "}
            <img style={{width:'100%', marginBottom:'15px'}} src={image} />
            <h3>Archive</h3>
            {this.state.archive.map((item) => (
              <Event date={item} />
            ))}
          </Col>
        </Row>

      </div>
    );
  }
}
export default Calendar;
