import React, { useEffect, useState } from "react";
import TitleBar from "./TitleBar";
import { getTeaching } from '../contentful'
import marked from 'marked'
import { Row, Col } from  'react-bootstrap'
import skype from '../assets/imgs/skype.jpg'
import zoom from '../assets/imgs/zoom.jpg'
import { Link } from 'react-router-dom'
const Teaching = () => {

const [ teaching, setTeaching ] = useState([])

useEffect(() => {
    getTeaching().then(data => setTeaching(data))
}, []
)






  return (
    <div className="contents">
      <TitleBar title="Teaching" />

      { teaching.map(teaching => {
          const content = marked(teaching.fields.mainText)
          return (
              <div>
                  <Row>
                  
                      <Col md={8}><div dangerouslySetInnerHTML={{__html: content}} />
                        <Link to="/contact" >
                        <div className="skype-logos">
                          <img src={skype} alt=""/>
                          <img src={zoom} alt=""/>
                        </div>
                        </Link>
                        </Col>
                      <Col md={4}>
                          <img style={{width:'100%'}} src={teaching.fields.mainImage.fields.file.url} alt=""/>
                      </Col>
                  </Row>
                  <hr/>
                <Row style={{marginTop:'20px'}}>
                    {teaching.fields.imageGallery.map(image => 
                    <Col md={4}><img style={{width: '100%'}} src={image.fields.file.url} alt=""/>
                    <small>{image.fields.description}</small>
                    </Col>)}
                </Row>



              </div>
          )





      })}
    </div>
  );
};

export default Teaching;
