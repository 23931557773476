import React from 'react'
import {
    TwitterShareButton,
    TwitterIcon,
    LinkedinShareButton,
    LinkedinIcon,
    FacebookIcon,
    FacebookShareButton,
  } from "react-share";

const TitleBar = (props) => {
    return (
        <div className="toolbar">
          <div>
    <h3>{props.title}</h3>
    <p>{props.subtitle}</p>
    </div>
    <div className="sharing-buttons">
            <small>Share this page on your social media</small><br></br>
              <TwitterShareButton
                url={window.location}
                children={<TwitterIcon size={24} />}
              />
              <LinkedinShareButton
                url={window.location}
                children={<LinkedinIcon size={24} />}
              />
              <FacebookShareButton
                url={window.location}
                children={<FacebookIcon size={24} />}
              />

            </div>    </div>
    )
}

export default TitleBar;